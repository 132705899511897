import $ from 'jquery';
window.jQuery = $;
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import slick from 'slick-carousel';
import matchHeight from 'jquery-match-height';
import fullpage from 'fullpage.js';

$(window).on('load', function () {
  /*
  $('.logo-slider').slick({
    rows: 0,
    infinite: true,
    arrows: false,
    speed: 750,
    cssEase: 'linear',
    autoplaySpeed: 0,
    autoplay: true,
    slidesToScroll: 1,
    swipe: false,
    swipeToSlide: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    variableWidth: true,
  }); */
});

let introTl = gsap
  .timeline({ defaults: { duration: 2.25, ease: 'power2.out' }, paused: true })
  .addLabel('start')
  .from('header .line', { width: 0, clearProps: 'width' })
  .from('.hero .hz', { width: 0, clearProps: 'width' }, '<')
  .from('.hero .grid-lines .line', { height: 0, duration: 2.25, clearProps: 'height' }, '<')
  .from('.hero .rotated-lines .line *', { height: 0, duration: 7, clearProps: 'height' }, '<')
  .from('header .container > svg', { yPercent: 101, stagger: 0.15, duration: 0.5 }, 'start')
  .from('header .container h1', { yPercent: 101, stagger: 0.15, duration: 0.5 }, '<')
  .from('.hero .internal', { yPercent: 110, stagger: 0.15, duration: 1 }, 'start+=0.5');

let equationTl = gsap
  .timeline({ defaults: { duration: 3, ease: 'power4.easeIn' }, paused: true })
  .from('.equation svg > g:nth-child(odd)', { x: '-100%', scale: 1, stagger: 0, clearProps: 'transform' })
  .from('.equation svg > g:nth-child(even)', { x: '100%', scale: 1, stagger: 0, clearProps: 'transform' }, '<');

let aboutTl = gsap
  .timeline({ defaults: { duration: 2.25, ease: 'power2.out' }, paused: true })
  .addLabel('start')
  .from('.about .hz, .about ul .line', { width: 0, clearProps: 'width' })
  .from('.about .grid-lines .line', { height: 0, clearProps: 'height' }, '<')

  .from('.about p', { yPercent: 101, stagger: 0.5, duration: 1.5 }, 'start+=1')
  .from('.about .desktop li span', { yPercent: 101, stagger: 0.15, duration: 1.5 }, '<0.3');

let listTl = gsap.timeline({ defaults: { duration: 0.5, ease: 'power2.out' }, paused: true }).from('.list li span', { y: '101%', stagger: 0, clearProps: 'transform', delay: 0.3 });

let footer1Tl = gsap
  .timeline({ defaults: { duration: 2.25, ease: 'power2.out' }, paused: true })
  .addLabel('start')
  .from('footer.first .hz', { width: 0, clearProps: 'width' })
  .from('footer.first .line', { height: 0, duration: 2.25, clearProps: 'height' }, '<')

  .from('footer.first .internal', { yPercent: 101, stagger: 0.15, duration: 1 }, 'start+=0.5')
  .from('footer.first a.contact span', { yPercent: 101, stagger: 0.15, duration: 1, clearProps: 'transform' }, '<0.5');

let footer2Tl = gsap
  .timeline({ defaults: { duration: 2.25, ease: 'power2.out' }, paused: true })
  .addLabel('start')
  .from('footer.last .hz', { width: 0, clearProps: 'width' })
  .from('footer.last .line', { height: 0, duration: 2.25, clearProps: 'height' }, '<')

  .from('footer.last h4 span', { yPercent: 101, duration: 1 }, '<')
  .from('footer.last a.email span, footer .wrapper p, footer a svg', { yPercent: 120, duration: 1 }, '<0.5');

let fullPageActive = false;

$(window).on('load resize', function () {
  if ($(window).width() > 767) {
    if (fullPageActive) {
      fullpage_api.destroy('all');
      fullPageActive = false;
    }

    ScrollTrigger.create({
      trigger: $('.equation'),
      animation: equationTl,
      toggleActions: 'play none none none',
      once: true,
    });

    gsap.to('.equation svg#desktop', {
      scrollTrigger: {
        trigger: '.equation',
        start: 'top bottom',
        scrub: true,
      },
      transform: 'translate3d(0,0,200px)',
    });

    gsap.to('.equation svg#tablet', {
      scrollTrigger: {
        trigger: '.equation',
        start: 'top bottom',
        scrub: true,
      },
      transform: 'translate3d(0,0,200px)',
    });

    ScrollTrigger.create({
      trigger: $('.about'),
      animation: aboutTl,
      toggleActions: 'play none none none',
      once: true,
    });

    ScrollTrigger.create({
      trigger: $('footer.first'),
      animation: footer1Tl,
      toggleActions: 'play none none none',
      once: true,
    });

    ScrollTrigger.create({
      trigger: $('footer.last'),
      animation: footer2Tl,
      toggleActions: 'play none none none',
      once: true,
    });
  } else {
    if (!fullPageActive) {
      fullPageActive = true;
      new fullpage('#fullpage', {
        licenseKey: '4NLK8-65IP8-7IKL8-080J9-UJTPO',
        onLeave: function (origin, destination, direction, trigger) {
          box.style.display = 'none';
          var leavingSection = this;

          if (origin.index == 0 && direction == 'down') {
            equationTl.play();
            $('.counter').text('2/6');
            $('.counter').addClass('reverse');
          }
          if (origin.index == 1 && direction == 'down') {
            equationTl.reverse();
            aboutTl.play();
            $('.counter').text('3/6');
            $('.counter').removeClass('reverse');
          }
          if (origin.index == 1 && direction == 'up') {
            equationTl.reverse();
            $('.counter').text('1/6');
            $('.counter').removeClass('reverse');
          }

          if (origin.index == 2 && direction == 'down') {
            listTl.play();
            $('.counter').text('4/6');
            $('.counter').removeClass('reverse');
          }

          if (origin.index == 2 && direction == 'up') {
            equationTl.play();
            $('.counter').text('2/6');
            $('.counter').addClass('reverse');
          }

          if (origin.index == 3 && direction == 'up') {
            $('.counter').text('3/6');
            $('.counter').removeClass('reverse');
          }
          if (origin.index == 3 && direction == 'down') {
            footer1Tl.play();
            $('.counter').text('5/6');
            $('.counter').removeClass('reverse');
          }

          if (origin.index == 4 && direction == 'up') {
            $('.counter').text('3/6');
            $('.counter').removeClass('reverse');
          }

          if (origin.index == 4 && direction == 'down') {
            footer2Tl.play();
            $('.counter').text('6/6');
            $('.counter').removeClass('reverse');
          }

          if (origin.index == 5 && direction == 'up') {
            $('.counter').text('5/6');
            $('.counter').removeClass('reverse');
          }
        },
      });

      fullpage_api.setMouseWheelScrolling(false);
      fullpage_api.setAllowScrolling(false);
    }
  }
});

$(window).on('load', function () {
  $('.logo-slide .slide-inner').matchHeight();

  setTimeout(function () {
    $('.load-overlay').hide();
    $('body').addClass('loaded');
    if (fullPageActive) {
      fullpage_api.setMouseWheelScrolling(true);
      fullpage_api.setAllowScrolling(true);
    }

    introTl.play();
  }, 6500);
});

// Get the box element
const box = document.querySelector('.hover-box');
const body = document.body;
const listItems = document.querySelectorAll('.about ul li span');

// Add an event listener for the mousemove event
body.addEventListener('mousemove', (event) => {
  // Get the mouse position
  const mouseX = event.clientX;
  const mouseY = event.clientY;

  // Calculate the center of the box
  const boxHalfHeight = box.offsetHeight / 2;

  // Set the box's position
  box.style.top = `${mouseY - boxHalfHeight + 18}px`;
  box.style.left = `${mouseX + 36}px`;
});

listItems.forEach((li) => {
  li.addEventListener('mouseenter', (event) => {
    box.style.display = 'block';
    let img = box.querySelector('img');
    const src = li.getAttribute('rel');

    img.setAttribute('src', src);
  });
});

listItems.forEach((li) => {
  li.addEventListener('mouseleave', (event) => {
    box.style.display = 'none';
  });
});

document.addEventListener('click', function (event) {
  if (Array.from(listItems).includes(event.target)) {
  } else {
    box.style.display = 'none';
  }
});

document.addEventListener('scroll', function () {
  box.style.display = 'none';
});

$('.down').on('click', function () {
  $('html, body').animate(
    {
      scrollTop: $('.equation').offset().top,
    },
    1000
  );
});
$('.counter').on('click', function () {
  fullpage_api.moveSectionDown();
});
